import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";


export const useStore = defineStore("ais", {
    state: () => ({
        user: useStorage("user", {} as any),
        userAccount: useStorage("userAccount", {} as any),
        userInfo: useStorage("userInfo", {} as any),
        referralInfo: useStorage("referralInfo", {} as any),
        walletAddress: useStorage("walletAddress", ""),
        walletConnect: useStorage("walletConnect", false),
        otpConnect: useStorage("otpConnect", false),
    }),
    actions: {
        setUser(user: any) {
            this.user = user;
        },
        setUserAccount(userAccount: any){
            this.userAccount = userAccount;
        },
        setUserInfo(userInfo: any){
            this.userInfo = userInfo;
        },
        setReferralInfo(referralInfo: any){
            this.referralInfo = referralInfo;
        },
        setWalletAddress(walletAddress: any){
            this.walletAddress = walletAddress;
        },
        setWalletConnect(walletConnect: boolean){
            this.walletConnect = walletConnect;
        },
        setOtpConnect(otpConnect: boolean){
            this.otpConnect = otpConnect;
        },
    },
});
