import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { getTimeDiff, formattedDate } from "./service/index";
import { defineEmits, defineProps, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import axios from "./axios";
import { useStore } from "./pinia";

export default function useBaseComponent() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const i18n = useI18n({ useScope: "global" });

    return {
        axios,
        dayjs,
        duration,
        getTimeDiff,
        formattedDate,
        i18n,
        route,
        router,
        store,
        defineEmits,
        defineProps,
        onMounted,
        onUnmounted,
        reactive,
        ref,
        watch
    };
}
