<template>
    <router-view :key="$route.fullPath" />
</template>

<script lang="ts" setup>
</script>

<style>

</style>
