import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { useStore } from "@/pinia";
import { i18n } from "./i18n";
import axios from "./axios";
import vue3GoogleLogin from 'vue3-google-login'

import '@/assets/css/reset.css'
import '@/assets/css/views.css'
import '@/assets/css/components.css'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import Menu from "@/components/Menu.vue";
import Header from "@/components/Header.vue";
import PopupTwoBtn from "@/components/PopupTwoBtn.vue";
import WithdrawPopup from "@/components/WithdrawPopup.vue";
import MetaMaskWallet from "@/components/MetaMaskWallet.vue";

import { VueClipboard } from "@soerenmartius/vue3-clipboard";

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);
app.use(router);
const useI18n = i18n();
app.use(useI18n);
app.use(VueClipboard);
app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});

app.component("Menu", Menu);
app.component("Header", Header);
app.component("PopupTwoBtn", PopupTwoBtn);
app.component("WithdrawPopup", WithdrawPopup);
app.component("MetaMaskWallet", MetaMaskWallet);
app.component("VPagination", VPagination);

app.mount("#app");