import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Index from '../views/Index.vue'
import Dashboard from '../views/Dashboard.vue'
import Node from '../views/Node.vue'
import Referrals from '../views/Referrals.vue'
import History from '../views/History.vue'
import Settings from '../views/Settings.vue'
import Authenticator from '../views/Authenticator.vue'
import Login from '../views/Login.vue'
import Sign from '../views/Sign.vue'
import Forget from '../views/Forget.vue'

const routes: Array<RouteRecordRaw> = [
  { path: "/", name: "Index", component: Index }, // 메인 페이지
  { path: "/dashboard", name: "dashboard", component: Dashboard }, // 대쉬보드 페이지
  { path: "/dashboard/node", name: "node", component: Node }, // 노드 페이지
  { path: "/referrals", name: "referrals", component: Referrals }, // 레퍼럴 페이지
  { path: "/history", name: "history", component: History }, // 히스토리 페이지
  { path: "/settings", name: "settings", component: Settings }, // 세팅 페이지
  { path: "/authenticator", name: "authenticator", component: Authenticator }, // 구글 인증 페이지
  { path: "/auth/signup", name: "sign", component: Sign }, // 가입 페이지
  { path: "/auth/login", name: "login", component: Login }, // 로그인 페이지
  { path: "/auth/forget", name: "forget", component: Forget }, // 비밀번호 찾기 페이지
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
