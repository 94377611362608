import dayjs, {Dayjs} from 'dayjs'
import duration, {Duration} from 'dayjs/plugin/duration'
dayjs.extend(duration)

export function getTimeDiff(timeToCompare: Dayjs): string {
	const timeDiffDuration: Duration = dayjs.duration(dayjs().diff(timeToCompare))
	const yearDiff: number = parseInt(timeDiffDuration.format('Y'))
	const monthDiff: number = parseInt(timeDiffDuration.format('M'))
	const dateDiff: number = parseInt(timeDiffDuration.format('D'))
	const hourDiff: number = parseInt(timeDiffDuration.format('H'))
	const minuteDiff: number = parseInt(timeDiffDuration.format('m'))
	const secondDiff: number = parseInt(timeDiffDuration.format('s'))



	const weekDiff = Math.floor(dateDiff / 7);

	if(yearDiff > 0){
		return `${yearDiff}년 전`
	} else if (monthDiff > 0){
		return `${monthDiff}달 전`
	} else if (weekDiff > 0) {
		return `${weekDiff}주 전`
	} else if (dateDiff > 0){
		return `${dateDiff}일 전`
	} else if (hourDiff > 0){
		return `${hourDiff}시간 전`
	} else if (minuteDiff > 0){
		return `${minuteDiff}분 전`
	} else if (secondDiff > 0){
		return `${secondDiff}초 전`
	} else {
		return ''
	}
}

export function formattedDate(date: Date){
	return date.toLocaleString('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});
  }

declare global {
    interface Window {
        ethereum: any;
        klaytn: any;
    }
}