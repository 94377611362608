import { createI18n } from "vue-i18n";
import en from "./json/en.json";
import ko from "./json/ko.json";

export type localeType = "en" | "ko";

const i18n = () => {
    return createI18n({
        locale: "ko",
        fallbackLocale: "ko",
        messages: { ko, en },
        legacy: false,
        globalInjection: true,
    });
};

export { i18n };
