import axios, { AxiosInstance } from "axios";
import {
    emailVerification,
    verificationCodeCheck,
    signUp,
    login,
    loginGoogle,
    logout,
    forget,
    userInfo,
    tierSearch,
    tierUpgrade,
    tierVerification,
    claim,
    nodeCreate,
    nodeIndex_validation,
    nodeInfo,
    nodeInfoAis,
    nodeMiniInfo,
    nodeMiniCreate,
    nodeMiniIndex_validation,
    nodeCount,
    allowanceInfo,
    settingReferralInfo,
    referralsUsers,
    referralsNodes,
    referralNodeInfo,
    allowancerewards,
    allowanceRewardsMore,
    allowanceWithdraw,
    userWallet,
    otpRequest,
    otpRegister,
    otpConnectStatus,
    otpDisconnect,
    changeReferralCode,
} from "./interface";
import { iRes } from "./commonResponse";

export class ResData extends iRes {
    [x: string]: any;
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    transformRequest: [
        (data) => {
            return JSON.stringify(data);
        },
    ],
    transformResponse: [
        (data) => {
            return JSON.parse(data);
        },
    ],
    headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Pragma: "no-cache",
    },
});

const axiosCommonResponse = (url: string, data?: any) => {
    return new Promise<ResData>((resolve, reject) => {
        axiosInstance
            .post(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const axiosMethods = {
    // 인증번호 전송
    emailVerification(param: emailVerification): Promise<any> {
        return axiosCommonResponse("/ais/authKey/auth/emailVerification", param);
    },
    // 인증번호 체크
    verificationCodeCheck(param: verificationCodeCheck): Promise<any> {
        return axiosCommonResponse("/ais/authKey/auth/verificationCodeCheck", param);
    },
    // 회원가입
    signUp(param: signUp): Promise<any> {
        return axiosCommonResponse("/ais/authKey/auth/signUp", param);
    },
    // 로그인
    login(param: login): Promise<any> {
        return axiosCommonResponse("/ais/authKey/login", param);
    },
    // 구글 로그인
    loginGoogle(param: loginGoogle): Promise<any> {
        return axiosInstance.get("/ais/authKey/login/google", {
            params: param,
        });
    },
    // 로그아웃
    logout(param: logout): Promise<any> {
        return axiosCommonResponse("/ais/authKey/logout", param);
    },
    // 비밀번호 재설정
    forget(param: forget): Promise<any> {
        return axiosCommonResponse("/ais/authKey/forget", param);
    },

    // 내 정보
    userInfo(param: userInfo): Promise<any> {
        return axiosCommonResponse("/ais/authKey/user/info", param);
    },

    // 등급 업그레이드 조회
    tierSearch(param: tierSearch): Promise<any> {
        return axiosCommonResponse("/ais/authKey/tier/search", param);
    },
    // 등급 업그레이드 요청
    tierUpgrade(param: tierUpgrade): Promise<any> {
        return axiosCommonResponse("/ais/authKey/tier/tierUpgrade", param);
    },
    // 등급 업그레이드 확인
    tierVerification(param: tierVerification): Promise<any> {
        return axiosCommonResponse("/ais/authKey/tier/verification", param);
    },

    // 출금
    claim(param: claim): Promise<any> {
        return axiosCommonResponse("/ais/authKey/claim", param);
    },
    // 노드 구매 정보
    nodeInfo(param: nodeInfo): Promise<any> {
        return axiosCommonResponse("/ais/authKey/node/info", param);
    },
    // 노드 구매 ais ticker 정보
    nodeInfoAis(param: nodeInfoAis): Promise<any> {
        return axiosCommonResponse("/ais/authKey/node/info/ais", param);
    },

    // 노드 구매
    nodeCreate(param: nodeCreate): Promise<any> {
        return axiosCommonResponse("/ais/authKey/node/create", param);
    },
    // 노드 구매
    nodeIndex_validation(param: nodeIndex_validation): Promise<any> {
        return axiosCommonResponse("/ais/authKey/node/index_validation", param);
    },

    // 노드 구매 정보
    nodeMiniInfo(param: nodeMiniInfo): Promise<any> {
        return axiosCommonResponse("/ais/authKey/node/mini/info", param);
    },
    // 노드 구매
    nodeMiniCreate(param: nodeMiniCreate): Promise<any> {
        return axiosCommonResponse("/ais/authKey/node/mini/create", param);
    },
    // 노드 구매
    nodeMiniIndex_validation(param: nodeMiniIndex_validation): Promise<any> {
        return axiosCommonResponse("/ais/authKey/node/mini/index_validation", param);
    },

    // 내 노드 수량
    nodeCount(param: nodeCount): Promise<any> {
        return axiosCommonResponse("/ais/authKey/node/nodeCount", param);
    },
    // 내 Allowance
    allowanceInfo(param: allowanceInfo): Promise<any> {
        return axiosCommonResponse("/ais/authKey/allowance/info", param);
    },
    // Roles Ratio
    settingReferralInfo(param: settingReferralInfo): Promise<any> {
        return axiosCommonResponse("/ais/authKey/settingReferral/info", param);
    },

    // 레퍼럴 유저
    referralsUsers(param: referralsUsers): Promise<any> {
        return axiosCommonResponse("/ais/authKey/referrals/users", param);
    },
    // 레퍼럴 노드
    referralsNodes(param: referralsNodes): Promise<any> {
        return axiosCommonResponse("/ais/authKey/referrals/nodes", param);
    },
    // 레퍼럴 노드 정보
    referralNodeInfo(param: referralNodeInfo): Promise<any> {
        return axiosCommonResponse("/ais/authKey/referral/node/info", param);
    },
    // 히스토리 리워드
    allowanceRewards(param: allowancerewards): Promise<any> {
        return axiosCommonResponse("/ais/authKey/allowance/rewards", param);
    },
    // 히스토리 more 리워드
    allowanceRewardsMore(param: allowanceRewardsMore): Promise<any> {
        return axiosCommonResponse("/ais/authKey/allowance/rewards/more", param);
    },
    // 히스토리 출금
    allowanceWithdraw(param: allowanceWithdraw): Promise<any> {
        return axiosCommonResponse("/ais/authKey/allowance/withdraw", param);
    },

    userWallet(param: userWallet): Promise<any> {
        return axiosCommonResponse("/ais/authKey/user/wallet", param);
    },

    // 키 생성
    otpRequest(param: otpRequest): Promise<any> {
        return axiosCommonResponse("/ais/authKey/otp/request", param);
    },
    // 키 등록
    otpRegister(param: otpRegister): Promise<any> {
        return axiosCommonResponse("/ais/authKey/otp/register", param);
    },
    // otp 연결 상태
    otpConnectStatus(param: otpConnectStatus): Promise<any> {
        return axiosCommonResponse("/ais/authKey/otp/connect/status", param);
    },
    // otp 연결 해제
    otpDisconnect(param: otpDisconnect): Promise<any> {
        return axiosCommonResponse("/ais/authKey/otp/disconnect", param);
    },
    // User Count
    userCount(): Promise<any> {
        return axiosCommonResponse("/ais/authKey/user/count");
    },
    // changeReferralCode
    changeReferralCode(param: changeReferralCode): Promise<any> {
        return axiosCommonResponse("/ais/authKey/user/referralCode/change", param);
    },
};

export default { axiosInstance, axiosMethods };
